/* manrope-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Manrope/manrope-v13-latin-regular.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/fonts/Manrope/manrope-v13-latin-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-500 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/Manrope/manrope-v13-latin-500.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/fonts/Manrope/manrope-v13-latin-500.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-600 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/Manrope/manrope-v13-latin-600.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/fonts/Manrope/manrope-v13-latin-600.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/Manrope/manrope-v13-latin-700.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/fonts/Manrope/manrope-v13-latin-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-800 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    src: url('/fonts/Manrope/manrope-v13-latin-800.woff2') format('woff2'),
        /* Chrome 36+, Opera 23+, Firefox 39+ */ url('/fonts/Manrope/manrope-v13-latin-800.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
